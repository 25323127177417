<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">供应商对账结算</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
					<el-form-item style="display:flex;" label="支付时间：">
                        <el-date-picker
                        v-model="dateBetween"
                        @change="selecttime"
                        type="datetimerange"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        :default-time="['00:00:00']">
                        </el-date-picker>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-select  v-model="seachForm.orderResultStatus" clearable placeholder="请选择结算状态">
							<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-input  :maxlength="50" style="width:300px;" v-model="seachForm.searchInfo" placeholder="请输入供应商/支付交易号/订单编号" clearable></el-input>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(seachForm)">搜索</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" ref="elTable" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="orderNo" label="订单编号" width="150"></el-table-column>
				<el-table-column prop="orderSerialNo" label="支付交易号" width="170"></el-table-column>
				<el-table-column prop="nickName" label="支付人" width="150"></el-table-column>
				<el-table-column prop="phone" label="手机号"  width="150"></el-table-column>
				<el-table-column prop="payTime" label="支付时间"  width="180"></el-table-column>
				<el-table-column prop="receiveTime" label="收货时间"  width="180"></el-table-column>
				<el-table-column prop="supplierName" label="供应商名称"  width="150"></el-table-column>
				<el-table-column prop="payGoodsAmount" label="订单金额"  width="100"></el-table-column>
				<el-table-column prop="dinddjf" label="订单积分"  width="100"></el-table-column>
				<el-table-column prop="supplierYingjsjf" label="供应商应结算积分" width="150"></el-table-column>
				<el-table-column prop="supplierYijsjf" label="供应商已结算积分" width="150"></el-table-column>
				<el-table-column prop="supplierWeijsjf" label="供应商未结算积分" width="150"></el-table-column>
				<el-table-column prop="platformYingjsjf" label="平台应结算积分" width="150"></el-table-column>
				<el-table-column prop="platformYijsjf" label="平台已结算积分" width="150"></el-table-column>
				<el-table-column prop="platformWeijsjf" label="平台未结算积分" width="150"></el-table-column>
				<el-table-column prop="resultTime" label="结算日期" width="150"></el-table-column>
				<el-table-column prop="orderResultStatus" label="结算状态" width="120">
					<template slot-scope="scope">
                        <span >{{scope.row.orderResultStatus == 0 ? '未结算' : '已结算'}}</span>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="150">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="LookXq(scope.$index, data)" type="text" size="small">
							查看详情
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		imageUrl
	} from '@/config/public';
	import {
		supplierorderList 
	} from "@/api/platform/index";
	export default {
		name: "supplierorderList",
		data() {
			return {
				image_url: imageUrl,
                checkStatus:'1',
                seachForm:{
					orderResultStatus:'',
					searchInfo:'',
					payTime:'',
				},
				statusOptions: [{label: '未结算',value: 0},{label: '已结算',value: 1}],
				dialogVisible: false,
				data: [],
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
                moreAudit:false,
				dateBetween:'',
				userName:'',
				explain:'',
				id:'',
				previewUrl:'',
				previewShow:false
			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				supplierorderList({
					payTime:this.seachForm.payTime,
					searchInfo:this.seachForm.searchInfo,
					orderResultStatus:this.seachForm.orderResultStatus,
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},
            
            //搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},

			// 筛选开始结束日期
            selecttime(){
                if(this.dateBetween === null){
                    this.seachForm.payTime = '';
                    return
                }
                let startime = this.dateBetween[0];
                let y = startime.getFullYear();
                let m = startime.getMonth() + 1;
                let r = startime.getDate();
                let h = startime.getHours();
                let mm = startime.getMinutes();
                let s = startime.getSeconds();
                m < 10 ? m = '0' + m : ''
                r < 10 ? r = '0' + r : ''
                h < 10 ? h = '0' + h : ''
                mm < 10 ? mm = '0' + mm : ''
                s < 10 ? s = '0' + s : ''
                let datetime = y + '-' + m + '-' + r +' '+ h + ":" + mm + ":" + s;
                let endtime = this.dateBetween[1];
                let y1 = endtime.getFullYear();
                let m1 = endtime.getMonth() + 1;
                let r1 = endtime.getDate();
                let h1 = endtime.getHours();
                let mm1 = endtime.getMinutes();
                let s1 = endtime.getSeconds();
                m1 < 10 ? m1 = '0' + m1 : ''
                r1 < 10 ? r1 = '0' + r1 : ''
                h1 < 10 ? h1 = '0' + h1 : ''
                mm1 < 10 ? mm1 = '0' + mm1 : ''
                s1 < 10 ? s1 = '0' + s1 : ''
                let datetime1 = y1 + '-' + m1 + '-' + r1 +' '+ h1 + ":" + mm1 + ":" + s1;
                this.seachForm.payTime  = datetime + ' ~ ' + datetime1;
            },
            
			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

			// 查看详情
			LookXq(index,data){
				this.$router.push({ name: '/accountXq', params: { parentId: data[index].id }})
			}
			
		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:300px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
</style>
